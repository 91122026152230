<template>
    <section
        class="m-0 p-0"
        style="
            background-image: url('layout/images/bg_evento_nao_encontrado.jpg');
            height: 100vh;
            width: 100vw;
            background-size: cover;
            background-attachment: fixed;
        "
    >
        <div class="m-auto text-white info">
            <div class="w-10 m-auto">
                <div class="mb-4">
                    <img class="mt-2" src="layout/images/logoSebrae.svg" alt="sebrae-logo" style="width: 110px" />
                    <h1 class="m-0 mt-2 p-0">Seu link de pagamento expirou</h1>
                    <h2 class="m-0 p-0 mt-1 line-height-2 font-light">
                        Para mais informações, entre em <br />
                        contato com o atendimento Sebrae
                    </h2>
                </div>
                <div>
                    <div class="flex flex-column md:flex-row md:mb-0 mb-6">
                        <div class="flex flex-column md:flex-row align-items-start pb-6" style="gap: 4px">
                            <!-- <i class="pi pi-phone text-sm"></i> -->
                            <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="34px" fill="#e8eaed">
                                <path
                                    d="M480-72v-72h264v-48H576v-288h168v-48q0-112-77.24-188-77.23-76-186.5-76Q371-792 293.5-716 216-640 216-528v48h168v288H216q-29-2-50.5-22T144-264v-263.84q0-69.72 26.5-131.44T243-766.5Q289-812 350.04-838q61.04-26 130-26 68.96 0 130.46 26 61.5 26 107 71.5t72 107.17Q816-597.67 816-528v384q0 29-21.15 50.5T744-72H480ZM216-264h96v-144h-96v144Zm432 0h96v-144h-96v144ZM216-408h96-96Zm432 0h96-96Z"
                                />
                            </svg>
                            <div>
                                <span class="telefone">0800 570 0800</span>
                                <div class="flex flex-column font-sans-serif mt-2">
                                    <span class="hora-atendimento">*Horario de atendimento de</span>
                                    <span class="hora-atendimento">segunda a sexta, 8h às 18h.</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="flex align-items-center" style="gap: 6px">
                                <i class="pi pi-envelope" style="color: #698190; font-size: 18px"></i>
                                <span style="color: #698190; font-size: 16px">relacionamento@pe.sebrae.com.br</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <a class="btn-white mt-5" :href="linkLoja" target="_blank"> Ir para a vitrine </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'LinkExpirado',
    data() {
        return {
            linkLoja: process.env.VUE_APP_API_URL_LOJA_CHECKOUT,
        };
    },
};
</script>

<style scoped>
p {
    padding: 0;
    margin: 0;
}

h1 {
    font-size: 45px;
}

.font-sans-serif {
    font-family: sans-serif;
}

.info {
    align-items: center;
    display: flex;
    /* justify-content: center; */
    height: 100vh !important;
}

.telefone {
    color: #fff !important;
    font-size: 22px;
    font-family: Roboto;
    font-weight: 700;
}

.hora-atendimento {
    color: #c9d5dc !important;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;
}

.btn-white {
    font-family: 'roboto';
    font-weight: 600;
    padding: 12px 30px;
    color: #2e66b7;
    background: #fff;
    border-radius: 4px;
    text-align: center !important;
    text-decoration: none !important;
    font-size: 15px;
}

/* Adicione estilos específicos para este componente aqui */
</style>
